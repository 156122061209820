.modal-template {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 3;
  visibility: hidden;
}

.modal-template__open {
  visibility: visible;
  opacity: 1;
}

.modal-template__form {
  width: 440px;
  border-radius: 20px;
  flex-direction: column;
  font-family: "Roboto Condensed", sans-serif, Arial;
  font-size: 30px;
  font-weight: bold;
  line-height: 20px;
  background: white;
  justify-content: space-between;
  display: flex;
  padding: 34px 36px 34px;
  position: absolute;
}

.modal-template__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 20px;
  color: #1a1b22;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 24px;
  font-weight: 900;
}

.modal-template__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: #00b3b3;
  outline: 3px #00b3b3 solid;
  outline-offset: -3px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: 400ms;
}

.modal-template__button-svg {
  height: 40px;
}

.modal-template__button-text {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
}

.modal-template__button .modal-template__button-text {
  color: white;
  font-weight: 700;
  transition: 400ms;
}

.modal-template__button .modal-template__button-svg path {
  transition: 400ms;
}

.modal-template__button:hover {
  background-color: transparent;
}

.modal-template__button:hover .modal-template__button-text {
  color: #00b3b3;
}

.modal-template__button:hover .modal-template__button-svg path {
  fill: #00b3b3;
}

.modal-template__button-close {
  background: url(../../images/close-button.svg) center no-repeat;
  height: 40px;
  width: 40px;
  border: none;
  background-color: transparent;
  position: absolute;
  top: -40px;
  right: -40px;
  cursor: pointer;
}

@media screen and (max-width: 670px) {
  .modal-template__form {
    width: 350px;
  }
}

@media screen and (max-width: 600px) {
  .modal-template__title {
    line-height: 25px;
  }
  .modal-template__form {
    width: 200px;
    padding: 25px 25px 25px;
  }
  .modal-template-submit__paragraph {
    font-size: 16px;
    margin: 0 0 20px;
  }
}

/* .modal {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 3;
  visibility: hidden;
}

.modal__open {
  visibility: visible;
  opacity: 1;
}

.modal__form {
  width: 440px;
  border-radius: 20px;
  flex-direction: column;
  font-family: "Roboto Condensed", sans-serif, Arial;
  font-size: 30px;
  font-weight: bold;
  line-height: 20px;
  background: white;
  justify-content: space-between;
  display: flex;
  padding: 34px 36px 34px;
  position: absolute;
}

.modal__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 20px;
  color: #1a1b22;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 24px;
  font-weight: 900;
}

.modal__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: hsl(345deg 100% 47%);
  outline: 3px hsl(345deg 100% 47%) solid;
  outline-offset: -3px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: 400ms;
}

.modal__button-svg {
  height: 40px;
}

.modal__button-text {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
}

.modal__button .modal__button-text {
  color: white;
  font-weight: 700;
  transition: 400ms;
}

.modal__button .modal__button-svg path {
  transition: 400ms;
}

.modal__button:hover {
  background-color: transparent;
}

.modal__button:hover .modal__button-text {
  color: hsl(345deg 100% 47%);
}

.modal__button:hover .modal__button-svg path {
  fill: hsl(345deg 100% 47%);
}

.modal__button-close {
  background: url(../../images/close-button.svg) center no-repeat;
  height: 40px;
  width: 40px;
  border: none;
  background-color: transparent;
  position: absolute;
  top: -40px;
  right: -40px;
  cursor: pointer;
}

@media screen and (max-width: 670px) {
  .modal__form {
    width: 350px;
  }
}

@media screen and (max-width: 600px) {
  .modal__form {
    width: 200px;
  }
  .modal-submit__paragraph {
    font-size: 16px;
    margin: 0 0 20px;
    line-height: 23px;
  }
} */
