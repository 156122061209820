.about-process {
  width: calc(100% - 80px);
  margin: 0 auto 150px;
}

.about-process__container {
  display: flex;
  flex-direction: column;
}

.about-process__title {
  font-family: "Lora", serif;
  text-align: center;
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 0 0 40px;
}

.about-process__card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}

.about-process__card {
  width: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #e5e5e5;
}

.about-process__card-image {
  width: 100%;
}

.about-process__card-title {
  font-family: "Lora", serif;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 1.5px;
  margin: 10px 0 5px;
}

.about-process__card-paragraph {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 30px;
  margin: 0;
}

@media screen and (max-width: 1300px) {
  .about-process__card-container {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 20px 20px;
  }
  .about-process__card {
    width: auto;
  }
}

@media screen and (max-width: 1100px) {
  .about-process__card-paragraph {
    line-height: 25px;
  }
}

@media screen and (max-width: 950px) {
  .about-process {
    width: calc(100% - 40px);
  }
  .about-process__title {
    margin: 0 0 60px;
  }
}

@media screen and (max-width: 800px) {
  .about-process__title {
    margin: 0 0 30px;
  }
}

@media screen and (max-width: 715px) {
  .about-process__title {
    font-size: 50px;
  }
}

@media screen and (max-width: 600px) {
  .about-process {
    margin: 0 auto 80px;
  }
  .about-process__card-container {
    display: flex;
    flex-direction: column;
    grid-gap: 40px 20px;
  }
  .about-process__card {
    padding: 50px;
  }
}

@media screen and (max-width: 550px) {
  .about-process__title {
    font-size: 40px;
  }
  .about-process__card {
    padding: 40px;
  }
}

@media screen and (max-width: 435px) {
  .about-process__title {
    font-size: 35px;
  }
  .about-process__card {
    padding: 30px;
  }
}
