.main-process {
  display: flex;
  flex-direction: row;
  justify-content: end;
  background: #00294b;
  height: 575px;
}

.main-process__textbox-container {
  max-width: 800px;
  margin: 100px 100px auto 20px;
  color: #f1f2f3;
}

.main-process__textbox {
}

.main-process__textbox-title {
  margin: 0;
  font-size: 40px;
  font-family: "lora", sans-serif, Arial;
  font-weight: 100;
}

.main-process__textbox-paragraph {
  font-family: "Figtree", sans-serif;
  margin: 30px 40px 100px 0;
  line-height: 30px;
  font-size: 18px;
  letter-spacing: 0.25px;
  font-weight: 100;
}

.main-process__button {
}

.main-process__image {
  width: 40%;
  background-image: url("../../images/main/ourProcess.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1550px) {
  .main-process__textbox-container {
    width: 60%;
    max-width: none;
  }
}

@media screen and (max-width: 950px) {
}

@media screen and (max-width: 770px) {
  .main-process {
    flex-direction: column;
    height: fit-content;
    margin: 0 0 100px;
  }
  .main-process__textbox-container {
    width: auto;
    max-width: none;
    margin: 80px 20px 40px;
  }
  .main-process__textbox-paragraph {
    margin: 30px 40px 60px 0;
  }
  .main-process__image {
    width: 100%;
    height: 240px;
  }
}

@media screen and (max-width: 550px) {
  .main-process {
    margin: 0 0 60px;
  }
}
