.main-subtitle {
}

.main-subtitle__text {
  font-family: "lora", sans-serif, Arial;
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  width: calc(100% - 80px);
  max-width: 830px;
  margin: 55px auto 55px;
}

@media screen and (max-width: 950px) {
  .main-subtitle__text {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 600px) {
  .main-subtitle__text {
    font-size: 35px;
  }
}
