.modal-email-list__paragraph {
  font-family: "Nunito", sans-serif;
  font-size: 20px;
  margin: 10px 0 0;
}

@media screen and (max-width: 1270px) {
  .modal-email-list__paragraph {
    max-width: 700px;
  }
}

@media screen and (max-width: 750px) {
  .modal-email-list__paragraph {
    max-width: 430px;
    font-size: 18px;
  }
}

@media screen and (max-width: 630px) {
  .modal-email-list__paragraph {
    max-width: 300px;
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .modal-email-list__paragraph {
    max-width: 290px;
  }
}
