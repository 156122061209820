.main-services {
  padding: 0 40px 0;
}

.main-services__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1500px;
  margin: auto;
}

.main-services__link {
  text-decoration: none;
  display: flex;
  width: 30%;
  max-width: 450px;
}

.main-services__card {
  display: flex;
  flex-direction: column;
  background-color: #00294b;
  height: fit-content;
  width: 100%;
  position: relative;
  transition: transform 0.2s ease-in;

  &:hover {
    transform: translate(0, -15px);
  }
}

.main-services__image {
  width: 100%;
}

.main-services__title {
  font-family: "Nunito", sans-serif;
  font-weight: 100;
  color: #f1f2f3;
  margin: 15px;
  text-align: center;
  font-size: 30px;
}

@media screen and (max-width: 1035px) {
  .main-services__title {
    height: 80px;
  }
}

@media screen and (max-width: 950px) {
  .main-services {
    padding: 0 20px 0;
  }
}

@media screen and (max-width: 625px) {
  .main-services__container {
    flex-direction: column;
    gap: 20px;
  }
  .main-services__link {
    width: 100%;
    max-width: none;
  }
  .main-services__title {
    height: auto;
  }
}
