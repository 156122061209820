.main-cta {
  background-image: url("../../images/main/cta.jpg");
  display: flex;
  flex-direction: column;
  justify-content: end;
  flex-wrap: wrap;
  align-content: center;
  width: 100%;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 60px 0 60px;
}

.main-cta__text {
  font-family: "lora", sans-serif, Arial;
  font-size: 40px;
  letter-spacing: 2px;
  font-weight: 500;
  margin: 0px 0 30px;
  color: #00294b;
}

.main-cta__button {
  width: 200px;
  align-self: center;
  margin: 0 0 60px;
}

@media screen and (max-width: 900px) {
  .main-cta {
    height: 250px;
  }
  .main-cta__button {
    margin: 0 0 40px;
  }
}

@media screen and (max-width: 600px) {
  .main-cta {
  }
  .main-cta__text {
    font-size: 35px;
    margin: 0px 0 35px;
  }
  .main-cta__button {
  }
}

@media screen and (max-width: 420px) {
  .main-cta {
  }
  .main-cta__text {
    font-size: 30px;
  }
  .main-cta__button {
  }
}
