.product-layout__color-section__header {
  font-family: "Lora", serif;
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  margin: 0;
  padding: 80px 0 40px;
}

.product-layout__color-section__header:first-of-type {
  text-align: center;
  font-size: 40px;
  margin: 0;
  padding: 20px 0 40px;
}

.product-layout__colors-section {
  max-width: calc(100% - 100px);
  box-sizing: border-box;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  font-family: "Inter", Arial, sans-serif;
}

.product-layout__colors-section__cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
  justify-content: center;
  grid-gap: 20px 18px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.product-layout__colors-section__card {
  font-family: "Nunito", sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 250px;
  width: 150px;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    transform: translate(0, -5px);
    border-radius: 4px;
  }
}

.product-layout__colors-section__card-image {
  width: 90%;
  height: 212px;
  border-radius: 0;
  margin: auto;
}

.product-layout__colors-section__card-title {
  font-family: "Nunito", sans-serif;
  max-width: 90%;
  margin: 0 auto 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
}

@media screen and (max-width: 950px) {
  .product-layout__colors-section {
    max-width: calc(100% - 60px);
  }
  .product-layout__colors-section__cards {
    grid-template-columns: repeat(auto-fit, minmax(130px, max-content));
  }
  .product-layout__colors-section__card {
    height: 216px;
    width: 130px;
  }
  .product-layout__colors-section__card-image {
    height: 185px;
    margin: auto auto 0;
  }
  .product-layout__colors-section__card-title {
    line-height: 25px;
    font-size: 14px;
  }
}

@media screen and (max-width: 851px) {
  .product-layout__colors-section {
    max-width: calc(100% - 50px);
  }
  .product-layout__colors-section__cards {
    grid-template-columns: repeat(auto-fit, minmax(120px, max-content));
  }
  .product-layout__colors-section__card {
    height: 200px;
    width: 120px;
  }
  .product-layout__colors-section__card-image {
    height: 170px;
  }
  .product-layout__colors-section__card-title {
    font-size: 13px;
    line-height: 26px;
  }
}

@media screen and (max-width: 680px) {
  .product-layout__colors-section {
    max-width: calc(100% - 20px);
  }
  .product-layout__colors-section__cards {
    grid-template-columns: repeat(auto-fit, minmax(96px, max-content));
  }
  .product-layout__colors-section__card {
    height: 160px;
    width: 96px;
  }
  .product-layout__colors-section__card-image {
    height: 134px;
    margin: auto auto 0;
  }
  .product-layout__colors-section__card-title {
    font-size: 10px;
    line-height: 22px;
  }
}
