.modal {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 3;
  visibility: hidden;
}

.modal__open {
  visibility: visible;
  opacity: 1;
}

.modal__form {
  display: flex;
  flex-direction: column;
  /* width: 440px; */
  border-radius: 20px;
  /* flex-direction: column; */
  /* font-family: "Roboto Condensed", sans-serif, Arial; */
  /* font-size: 30px; */
  font-weight: bold;
  /* line-height: 20px; */
  background: #f5f0f0;
  justify-content: space-between;
  /* padding: 34px 36px 34px; */
  padding: 24px 26px 24px;
  position: absolute;
}

.modal__title {
  font-family: "Lora", serif;
  text-align: center;
  height: 65px;
  /* transform: translateY(15px); */
  font-size: 40px;
  margin: 0;
  /* align-self: center; */
  /* font-weight: 700;
    font-size: 16px; */
  /* line-height: 20px; */
  /* margin: 0 0 20px; */
  color: #1a1b22;
  /* font-family: "Radio Canada Big", sans-serif, Arial; */
  /* font-size: 24px; */
  font-weight: 900;
}

.form__input-box {
  position: relative;
  align-self: center;
  width: 231px;
  justify-self: center;
}

.form__input-name {
  grid-row: 2;
  grid-column: 1;
}

.form__input-email {
  width: -webkit-fill-available;
}

.form__input-number {
  width: 218px;
  margin: 0 0 0 auto;
}

.form__input-services {
}

.form__input-message {
  width: 468px;
  height: 130px;
  margin: 10px 10px 0 0;
  align-self: start;
}

.form__input-button {
}

.form__input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form__input {
  padding: 10px 0 10px 10px;
  width: 220px;
  /* margin-left: 2rem; */
  border: 1px solid black;
  background-color: transparent;
  border-radius: 5px;
  outline: none;
  color: black;
  font-size: 15px;
  transition: 0.5s;
  font-family: "Roboto", sans-serif;
}

.form__email-input {
  padding: 10px 0 10px 10px;
  width: -webkit-fill-available;
  border: 1px solid black;
  background-color: transparent;
  border-radius: 5px;
  outline: none;
  color: black;
  font-size: 15px;
  transition: 0.5s;
  font-family: "Roboto", sans-serif;
}

.form__input-message-container {
  width: 466px;
  height: 95px;
  resize: none;
}

.form__input-span {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  /* margin-left: 2rem; */
  pointer-events: none;
  font-size: 15px;
  color: black;
  text-transform: uppercase;
  transition: 0.5s;
}

.form__input-box .form__input:valid ~ .form__input-span,
.form__input-box .form__input:focus ~ .form__input-span {
  color: black;
  transform: translateX(10px) translateY(-7px);
  font-size: 15px;
  padding: 0 5px;
  background-color: #f5f0f0;
  border-left: 1px solid black;
  border-right: 1px solid black;
  letter-spacing: 1px;
  height: 18px;
}

.form__input-box .form__email-input:valid ~ .form__input-span,
.form__input-box .form__email-input:focus ~ .form__input-span {
  color: black;
  transform: translateX(10px) translateY(-7px);
  font-size: 15px;
  padding: 0 5px;
  background-color: #f5f0f0;
  border-left: 1px solid black;
  border-right: 1px solid black;
  letter-spacing: 1px;
  height: 18px;
}

.form__input-box .form__input:valid,
.form__input-box .form__input:focus {
  border: 1px solid black;
}

.form__input-box .form__email-input:valid,
.form__input-box .form__email-input:focus {
  border: 1px solid black;
}

/* .react-select__container {
  background: transparent;
  border: 1px black solid;
  min-height: 38.67px;
  border-radius: 5px;
  outline: none;
  color: black;
  font-size: 15px;
  width: 478px;
  align-self: center;
  justify-self: center;
} */

/* .react-select__control {
  background: transparent;
  border-radius: 0;
} */

/* .react-select__value-container {
  font-size: 15px;
} */

/* .react-select-control {
  border-radius: 0;
  background: transparent;
} */

.modal__button {
  font-family: "Nunito", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: #c50b30;
  outline: 3px #c50b30 solid;
  outline-offset: -3px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: 400ms;
}

.modal__button-svg {
  height: 40px;
}

.modal__button-text {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
}

.modal__button .modal__button-text {
  color: white;
  font-weight: 700;
  transition: 400ms;
}

.modal__button .modal__button-svg path {
  transition: 400ms;
}

.modal__button:hover {
  background-color: transparent;
}

.modal__button:hover .modal__button-text {
  color: #c50b30;
}

.modal__button:hover .modal__button-svg path {
  fill: #c50b30;
}

.modal__button-close {
  background: url(../../images/close-button.svg) center no-repeat;
  height: 40px;
  width: 40px;
  border: none;
  background-color: transparent;
  position: absolute;
  top: -40px;
  right: -40px;
  cursor: pointer;
}

@media screen and (max-width: 1050px) {
  .modal__form {
    max-width: 700px;
  }
}

@media screen and (max-width: 925px) {
  .modal__form {
    max-width: 500px;
  }
}

@media screen and (max-width: 750px) {
  .modal__form {
    /* max-width: 400px; */
  }
}

@media screen and (max-width: 690px) {
  .modal__title {
    font-size: 38px;
    height: 63px;
  }
  .form__input {
    font-size: 14px;
  }
  .form__input-span {
    font-size: 14px;
  }
  .form__input-box .form__input:valid ~ .form__input-span,
  .form__input-box .form__input:focus ~ .form__input-span {
    font-size: 14px;
  }
  .form__input-box .form__email-input:valid ~ .form__input-span,
  .form__input-box .form__email-input:focus ~ .form__input-span {
    font-size: 14px;
  }
  .form__email-input {
    font-size: 14px;
  }
  /* .react-select__value-container {
    font-size: 14px;
  } */
  .form__input-name {
    width: 190px;
  }
  /* .form__input-email {
    width: 190px;
  } */
  .form__input-number {
    width: 180px;
  }
  .form__input-company {
    width: 190px;
  }
  .form__input {
    width: 180px;
  }
  /* .react-select__container {
    width: 391px;
    font-size: 14px;
  } */
  .form__input-message {
    width: 381px;
  }
  .form__input-message-container {
    width: 380px;
  }
}

@media screen and (max-width: 630px) {
  .modal__form {
    /* max-width: 400px; */
  }
  .modal__title {
    font-size: 32px;
  }
}

@media screen and (max-width: 610px) {
  .modal__title {
    /* font-size: 36px; */
    height: 61px;
  }
  .form__input {
    font-size: 13px;
  }
  .form__input-span {
    font-size: 13px;
  }
  .form__input-box .form__input:valid ~ .form__input-span,
  .form__input-box .form__input:focus ~ .form__input-span {
    font-size: 13px;
  }
  .form__input-box .form__email-input:valid ~ .form__input-span,
  .form__input-box .form__email-input:focus ~ .form__input-span {
    font-size: 13px;
  }
  .form__email-input {
    font-size: 13px;
  }
  /* .react-select__value-container {
    font-size: 13px;
  } */
  .form__input-name {
    width: 170px;
  }
  /* .form__input-email {
    width: 170px;
  } */
  .form__input-number {
    width: 160px;
  }
  .form__input-company {
    width: 170px;
  }
  .form__input {
    width: 160px;
  }
  /* .react-select__container {
    width: 354px;
    font-size: 13px;
  } */
  .form__input-message {
    width: 344px;
  }
  .form__input-message-container {
    width: 342px;
  }
}

@media screen and (max-width: 560px) {
  .modal__title {
    /* font-size: 34px; */
    height: 59px;
  }
  .form__input {
    font-size: 12px;
  }
  .form__input-span {
    font-size: 12px;
  }
  .form__input-box .form__input:valid ~ .form__input-span,
  .form__input-box .form__input:focus ~ .form__input-span {
    font-size: 12px;
  }
  .form__input-box .form__email-input:valid ~ .form__input-span,
  .form__input-box .form__email-input:focus ~ .form__input-span {
    font-size: 12px;
  }
  .form__email-input {
    font-size: 12px;
  }
  /* .react-select__value-container {
    font-size: 12px;
  } */
  .form__input-name {
    width: 140px;
  }
  /* .form__input-email {
    width: 140px;
  } */
  .form__input-number {
    width: 130px;
  }
  .form__input-company {
    width: 140px;
  }
  .form__input {
    width: 130px;
  }
  /* .react-select__container {
    width: 294px;
    font-size: 12px;
  } */
  .form__input-message {
    width: 284px;
  }
  .form__input-message-container {
    width: 282px;
  }
}

@media screen and (max-width: 500px) {
  .modal__button-close {
    top: -50px;
    right: -10px;
  }
}

@media screen and (max-width: 490px) {
  .modal__title {
    font-size: 32px;
    height: 57px;
  }
  /* .form__input {
    font-size: 11px;
  }
  .form__input-span {
    font-size: 11px;
  }
  .form__input-box .form__input:valid ~ .form__input-span,
  .form__input-box .form__input:focus ~ .form__input-span {
    font-size: 11px;
  }
  .form__input-box .form__email-input:valid ~ .form__input-span,
  .form__input-box .form__email-input:focus ~ .form__input-span {
    font-size: 11px;
  }
  .form__email-input {
    font-size: 11px;
  } */
  /* .react-select__value-container {
    font-size: 11px;
  }
  .form__input-name {
    width: 125px;
  }
  /* .form__input-email {
    width: 125px;
  } */
  .form__input-number {
    width: 115px;
  }
  .form__input-company {
    width: 125px;
  }
  .form__input {
    width: 115px;
  }
  /* .react-select__container {
    width: 264px;
    font-size: 11px;
  } */
  .form__input-message {
    width: 254px;
  }
  .form__input-message-container {
    width: 252px;
  }
}

@media screen and (max-width: 425px) {
  .modal__title {
    font-size: 32px;
    height: 57px;
  }
  .form__input-name {
    width: 120px;
  }
  /* .form__input-email {
    width: 120px;
  } */
  .form__input-number {
    width: 110px;
  }
  .form__input-company {
    width: 120px;
  }
  .form__input {
    width: 110px;
  }
  /* .react-select__container {
    width: 253px;
  } */
  .form__input-message {
    width: 243px;
  }
  .form__input-message-container {
    width: 242px;
  }
}
