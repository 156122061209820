.blogs {
  font-family: system-ui;
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  gap: 20px;
  width: calc(100% - 80px);
  margin: 20px auto 20px;
}

.blogs__container {
  background-color: #ffffff;
  max-width: 740px;
  padding: 80px 40px 40px;
}

.blogs__title {
  font-family: system-ui;
  font-size: 42px;
  line-height: 50px;
  font-weight: 400;
  margin: 0;
  max-width: none;
  width: auto;
  text-align: left;
  letter-spacing: normal;
}

.blogs__date-author {
  color: rgb(87, 87, 96);
  text-decoration: none;
  font-size: 17px;
  font-weight: 400;
  margin: 5px 0 40px;
}

.blogs__author-name {
  color: #1e73be;
  text-decoration: underline;
}

.blogs__image-container {
  display: flex;
  flex-direction: column-reverse;
  max-width: 900px;
  /* width: calc(100% - 80px); */
  margin: auto;
}

.blogs__image {
  width: 100%;
  margin: 30px 0 60px;
}

.blogs__image-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 0 0 0 40px; */
}

.blogs__image-title {
  /* font-family: "Lora", serif; */
  font-size: 35px;
  line-height: 42px;
  font-weight: 400;
  margin: 20px auto 20px 0;
}

.blogs__image-paragraph {
  /* font-family: "Nunito", sans-serif; */
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
  text-align: left;
  margin: 0 auto 0;
}

.blogs__subheader {
  /* font-family: "Lora", serif; */
  font-size: 35px;
  line-height: 42px;
  font-weight: 400;
  margin: 0;
  text-align: left;
  /* width: calc(100% - 80px); */
  margin: 0 auto 20px;
}

.blogs__paragraph {
  /* font-family: "Nunito", sans-serif; */
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
  text-align: left;
  margin: 0px auto 60px;
}

@media screen and (max-width: 1050px) {
  .blogs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .blogs__container {
    max-width: none;
  }
  .blogs__image-title {
    text-align: left;
    margin: 20px 0 20px;
  }
}

@media screen and (max-width: 950px) {
  .blogs {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 730px) {
  .blogs {
    width: auto;
  }
  .blogs__container {
    padding: 60px 20px 40px;
  }
  .blogs__title {
    font-size: 36px;
    line-height: 45px;
  }
  .blogs__date-author {
    font-size: 16px;
  }
  .blogs__image-title {
    font-size: 32px;
    line-height: 38px;
    margin: 10px 0 10px;
  }
  .blogs__image-paragraph {
    font-size: 18px;
    line-height: 30px;
  }
  .blogs__subheader {
    font-size: 32px;
    line-height: 38px;
    margin: 0 auto 10px;
  }
  .blogs__paragraph {
    font-size: 18px;
    line-height: 30px;
    margin: 0px auto 40px;
  }
}

@media screen and (max-width: 600px) {
  .blogs__container {
    padding: 40px 20px 40px;
  }
}

@media screen and (max-width: 550px) {
  .blogs__title {
    font-size: 34px;
    line-height: 43px;
  }
  .blogs__date-author {
    font-size: 14px;
    margin: 5px 0 30px;
  }
  .blogs__image-title {
    font-size: 30px;
    line-height: 36px;
    margin: 10px 0 10px;
  }
  .blogs__image {
    margin: 30px 0 50px;
  }
  .blogs__image-paragraph {
    font-size: 16px;
    line-height: 28px;
  }
  .blogs__subheader {
    font-size: 30px;
    line-height: 36px;
    margin: 0 auto 10px;
  }
  .blogs__paragraph {
    font-size: 16px;
    line-height: 28px;
    margin: 0px auto 40px;
  }
}
