$breakpoints: (
  "xs": 0,
  "sm": 480px,
  "md": 720px,
  "lg": 960px,
  "xl": 1200px,
);

@mixin xs {
  @media (min-width: map-get($breakpoints, "xs")) {
    @content;
  }
}

@mixin md {
  @media (min-width: map-get($breakpoints, "md")) {
    @content;
  }
}

@mixin lg {
  @media (min-width: map-get($breakpoints, "lg")) {
    @content;
  }
}

@mixin xl {
  @media (min-width: map-get($breakpoints, "xl")) {
    @content;
  }
}

@mixin breakpoint($bp: 0) {
  @media (min-width: $bp) {
    @content;
  }
}

.container {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
  .myswiper {
    width: 100%;
    height: 600px;

    @include xs {
      height: 300px;
    }

    @include md {
      height: 400px;
    }

    @include xl {
      height: 500px;
    }

    @include breakpoint(1400px) {
      height: 600px;
    }

    .swiperslide {
      -webkit-transform: translateZ(0);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .swiperNavNext,
    .swiperNavPrev {
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      z-index: 1;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      @include xs {
        height: 45px;
        width: 45px;
      }

      @include breakpoint(600px) {
        width: 50px;
        height: 50px;
      }
    }
    .swiperNavPrev {
      left: 0;
      background-image: url("../../images/icons/iconLeftArrow.png");
      margin-left: 40px;

      @include xs {
        margin-left: 30px;
      }

      @include breakpoint(600px) {
        margin-left: 40px;
      }
    }
    .swiperNavNext {
      right: 0;
      background-image: url("../../images/icons/iconRightArrow.png");
      margin-right: 40px;

      @include xs {
        margin-right: 30px;
      }

      @include breakpoint(600px) {
        margin-right: 40px;
      }
    }
  }
}
