.main-owners {
  background-color: #e5e5e5;
}

.main-owners__container {
  display: flex;
  flex-direction: row;
  gap: 80px;
  max-width: 1500px;
  padding: 80px 40px 80px;

  margin: auto;
}

.main-owners__image {
  width: 45%;
  background-image: url("../../images/showcaseCards/bathroomCabinetsNewCard.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-owners__text-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 55%;
}

.main-owners__subtitle {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 25px;
  margin: 0;
}

.main-owners__title {
  font-family: "lora", sans-serif, Arial;
  font-size: 48px;
  letter-spacing: 6px;
  font-weight: 500;
  margin: 50px 0 50px;
}

.main-owners__paragraph {
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 30px;
  font-size: 18px;
  margin: 0;
}

.main-owners__button {
  width: 200px;
  margin: 120px 0 0;
}

@media screen and (max-width: 1400px) {
  .main-owners__container {
    gap: 40px;
  }
  .main-owners__title {
    margin: 30px 0 30px;
  }
  .main-owners__button {
    margin: 60px 0 0;
  }
}

@media screen and (max-width: 1150px) {
  .main-owners__title {
    margin: 15px 0 15px;
  }
  .main-owners__subtitle {
    font-size: 22px;
  }
  .main-owners__title {
    font-size: 45px;
  }
  .main-owners__paragraph {
    font-size: 15px;
  }
}

@media screen and (max-width: 950px) {
  .main-owners__container {
    padding: 80px 20px 80px;
  }
}

@media screen and (max-width: 900px) {
  .main-owners__container {
    flex-direction: column;
    padding: 40px 20px 80px;
  }
  .main-owners__image {
    width: 100%;
    height: 400px;
  }
  .main-owners__title {
    margin: 15px 0 15px;
  }
  .main-owners__text-container {
    width: 100%;
  }
  .main-owners__subtitle {
  }
  .main-owners__title {
  }
  .main-owners__paragraph {
  }
}

@media screen and (max-width: 425px) {
  .main-owners__container {
    flex-direction: column;
    padding: 40px 20px 40px;
  }
  .main-owners__image {
  }
  .main-owners__title {
    font-size: 40px;
  }
  .main-owners__text-container {
  }
  .main-owners__subtitle {
  }
  .main-owners__title {
  }
  .main-owners__paragraph {
  }
}
