.blog-about {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blog-about__container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 230px;
  max-width: 360px;
  padding: 40px;
}

.blog-about__text {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  margin: 0;
}

.blog-about__logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.blog-about__logo {
  width: 25px;
  height: 25px;
}

.blog-about__logo-text {
  cursor: pointer;
  text-decoration: none;
  align-self: center;
  height: fit-content;
  margin: 0;
}

.facebook {
  color: #1e4ba0;
}

.instagram {
  color: #df3478;
}

@media screen and (max-width: 1050px) {
  .blog-about__container {
    min-width: none;
    max-width: none;
  }
}

@media screen and (max-width: 730px) {
  .blog-about__text {
    font-size: 18px;
    line-height: 30px;
  }
}

@media screen and (max-width: 550px) {
  .blog-about__text {
    font-size: 16px;
    line-height: 28px;
  }
}
