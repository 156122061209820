.k-cabinets {
}

.k-cabinets__parallax {
  height: 500px;
  width: 100%;
}

.k-cabinets__first-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 600px;
  width: calc(100% - 80px);
  margin: 0 auto 0;
}

.k-cabinets__first-section__description {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
}

.k-cabinets__first-section__header {
  font-family: "Lora", serif;
  font-size: 43px;
  font-weight: 400;
  letter-spacing: -2px;
  line-height: 60px;
  margin: 0;
}

.k-cabinets__first-section__paragraph {
  font-family: "Nunito", sans-serif;
  margin: 20px 0 30px;
  font-size: 20px;
}

.k-cabinets__first-section__button {
  width: 50%;
}

.k-cabinets__first-section__image {
  max-width: 800px;
  height: 450px;
  margin: auto 0 auto 0;
}

.k-cabinets__color-section {
  background-color: #e5e5e5;
  padding-bottom: 80px;
  width: calc(100% - 80px);
  margin: 0 auto 80px;
}

@media screen and (max-width: 1500px) {
  .k-cabinets__first-section__image {
    width: 700px;
  }
}

@media screen and (max-width: 1450px) {
  .k-cabinets__first-section__description {
    margin: auto auto auto 0;
  }
}

@media screen and (max-width: 1350px) {
  .k-cabinets__parallax {
    height: 450px;
  }
  .k-cabinets__first-section {
    height: 500px;
  }
  .k-cabinets__first-section__description {
    margin: auto 30px auto 0;
    max-width: 455px;
  }
  .k-cabinets__first-section__header {
    font-size: 40px;
  }
  .k-cabinets__first-section__paragraph {
    margin: 10px 0 30px;
    font-size: 20px;
  }
  .k-cabinets__first-section__button {
    width: 55%;
  }
  .k-cabinets__first-section__image {
    width: 600px;
    height: 400px;
  }
}

@media screen and (max-width: 1240px) {
  .k-cabinets__first-section {
    height: 450px;
  }
  .k-cabinets__first-section__description {
    max-width: 405px;
  }
  .k-cabinets__first-section__header {
    font-size: 35px;
  }
  .k-cabinets__first-section__paragraph {
    font-size: 18px;
  }
  .k-cabinets__first-section__button {
    width: 61%;
  }
  .k-cabinets__first-section__image {
    width: 540px;
    height: 350px;
  }
}

@media screen and (max-width: 1100px) {
  .k-cabinets__parallax {
    height: 400px;
  }
  .k-cabinets__first-section {
    height: 400px;
  }
  .k-cabinets__first-section__description {
    margin: auto 50px auto 0;
  }
  .k-cabinets__first-section__header {
    line-height: 35px;
  }
  .k-cabinets__first-section__image {
    width: 500px;
    height: 300px;
  }
}

@media screen and (max-width: 1050px) {
  .k-cabinets__parallax {
    height: 350px;
  }
  .k-cabinets__first-section {
    height: 350px;
  }
  .k-cabinets__first-section__description {
    max-width: 400px;
  }
  .k-cabinets__first-section__paragraph {
    font-size: 16px;
  }
  .k-cabinets__first-section__image {
    width: 413px;
    height: 280px;
  }
}

@media screen and (max-width: 950px) {
  .k-cabinets__parallax {
    height: 300px;
  }
  .k-cabinets__color-section {
    width: calc(100% - 40px);
  }
  .k-cabinets__first-section {
    height: 300px;
    width: calc(100% - 40px);
  }
  .k-cabinets__first-section__description {
    max-width: 330px;
  }
  .k-cabinets__first-section__header {
    line-height: 30px;
    font-size: 30px;
  }
  .k-cabinets__first-section__button {
    width: 74%;
  }
  .k-cabinets__first-section__image {
    width: 400px;
    height: 250px;
  }
}

@media screen and (max-width: 874px) {
  .k-cabinets__first-section {
    height: 300px;
  }
  .k-cabinets__first-section__description {
    max-width: 330px;
  }
  .k-cabinets__first-section__header {
    line-height: 30px;
    font-size: 30px;
  }
  .k-cabinets__first-section__button {
    width: 74%;
  }
  .k-cabinets__first-section__image {
    width: 350px;
    height: 230px;
  }
}

@media screen and (max-width: 825px) {
  .k-cabinets__first-section {
    height: 300px;
  }
  .k-cabinets__first-section__description {
    max-width: 268px;
  }
  .k-cabinets__first-section__header {
    font-size: 25px;
    line-height: 25px;
  }
  .k-cabinets__first-section__paragraph {
    font-size: 14px;
    margin: 10px 0 20px;
  }
  .k-cabinets__first-section__button {
    width: 90%;
  }
  .k-cabinets__first-section__image {
    width: 350px;
    height: 230px;
  }
}

@media screen and (max-width: 760px) {
  .k-cabinets__first-section {
    flex-direction: column;
    height: auto;
  }
  .k-cabinets__color-section {
    margin: 0 auto 60px;
  }
  .k-cabinets__first-section__description {
    margin: 30px auto 30px;
    max-width: none;
    width: calc(100% - 100px);
  }
  .k-cabinets__first-section__header {
    font-size: 30px;
    text-align: center;
  }
  .k-cabinets__first-section__paragraph {
    text-align: center;
  }
  .k-cabinets__first-section__button {
    width: 100%;
  }
  .k-cabinets__first-section__image {
    width: calc(100% - 100px);
    height: 270px;
    margin: 0 auto 60px;
  }
}

@media screen and (max-width: 680px) {
  .k-cabinets__color-section {
    padding-bottom: 40px;
  }
  .k-cabinets__first-section {
    flex-direction: column;
    height: auto;
  }
  .product-layout__color-section__header:first-of-type {
    font-size: 35px;
    padding: 20px 0 30px;
  }
  .k-cabinets__first-section__description {
    width: 100%;
  }
  .k-cabinets__first-section__button {
    width: 100%;
  }
  .k-cabinets__first-section__image {
    width: 100%;
    height: 300px;
  }
}

@media screen and (max-width: 560px) {
  .k-cabinets__first-section {
  }
  .k-cabinets__first-section__image {
    height: 250px;
  }
}

@media screen and (max-width: 480px) {
  .product-layout__color-section__header:first-of-type {
    font-size: 28px;
  }
  .k-cabinets__first-section {
  }
  .k-cabinets__first-section__header {
    font-size: 28px;
  }
  .k-cabinets__first-section__image {
    height: 200px;
  }
}
