@import url("https://fonts.googleapis.com/css2?family=Inclusive+Sans:ital@1&family=Playfair+Display:wght@700&family=Tilt+Neon&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Radio+Canada+Big:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@import url("/src/components/About/About.css");
@import url("/src/components/AboutHeader/AboutHeader.css");
@import url("/src/components/AboutOwners/AboutOwners.css");
@import url("/src/components/AboutProcess/AboutProcess.css");
@import url("/src/components/BookOnlineButton/BookOnlineButton.css");
@import url("/src/components/Blog/BlogAbout/BlogAbout.css");
@import url("/src/components/EmailListForm/EmailListForm.css");
@import url("/src/components/ContactForm/ContactForm.css");
@import url("/src/components/Footer/Footer.css");
@import url("/src/components/Blog/GeneralBlog/GeneralBlog.css");
@import url("/src/components/Blog/Blogs/Blogs.css");
@import url("/src/components/Blog/BlogCta/BlogCta.css");
@import url("/src/components/Header/Header.css");
@import url("/src/components/Gallery/GeneralGallery.css");
@import url("/src/components/Gallery/Galleries/Galleries.css");
@import url("/src/components/Gallery/GalleryCta.css");
@import url("/src/components/Dropdown/Dropdown.css");
@import url("/src/components/ItemModal/ItemModal.css");
@import url("/src/components/Gallery/ImageGallery.css");
@import url("/src/components/ProductLayout/ProductLayout.css");
@import url("/src/components/SidebarDropdownBulletpoint/SidebarDropdownBulletpoint.css");
@import url("/src/components/SidebarDropdownItem/SidebarDropdownItem.css");
@import url("/src/components/KitchenCabinets/KitchenCabinets.css");
@import url("/src/components/MainCta/MainCta.css");
@import url("/src/components/MainOwners/MainOwners.css");
@import url("/src/components/ModalEmailList/ModalEmailList.css");
@import url("/src/components/MainProcess/MainProcess.css");
@import url("/src/components/MainServices/MainServices.css");
@import url("/src/components/MainSubtitle/MainSubtitle.css");
@import url("/src/components/MainTitle/MainTitle.css");
@import url("/src/components/MainReviews/MainReviews.css");
@import url("/src/components/ModalContactForm/ModalContactForm.css");
@import url("/src/components/ModalGetAQuote/ModalGetAQuote.css");
@import url("/src/components/ModalTemplate/ModalTemplate.css");
@import url("/src/components/ModalContactForm/ModalContactForm.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f2f3;
  /* background-color: #ffa586; */

  font-family: "Rubik", sans-serif, Arial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
