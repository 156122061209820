.main-reviews {
  height: 400px;
  margin: 40px 0 40px;
}

.main-reviews__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: calc(100% - 180px);
  max-width: 1200px;
  margin: auto;
  height: 400px;
}

.main-reviews__review {
  display: flex;
  flex-direction: column;
  background-image: url("../../images/main/quoteBackground.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  gap: 20px;
  flex: 1;
  margin: 0;
  text-align: left;
  height: 400px;
  justify-content: center;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(161, 156, 161, 1);
  -moz-box-shadow: 0px 0px 10px 1px rgba(161, 156, 161, 1);
  box-shadow: 0px 0px 10px 1px rgba(161, 156, 161, 1);
}

.main-reviews__transition-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  height: 400px;
  justify-content: center;
  padding: 0 85px 0;

  opacity: 0;
  transition: opacity 0.75s ease, transform 0.75s ease;
  position: absolute;
  top: 0;
}

.main-reviews__transition-container.visible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.75s ease 0.75s, transform 0.75s ease 0.75s; /* Delay reappearance by 0.5s */
}

.main-reviews__transition-container.hidden {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.75s ease, transform 0.75s ease;
  pointer-events: none;
}

.main-reviews__image {
  width: 60px;
}

.main-reviews__quote {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  letter-spacing: 0.75px;
  margin: 0;
}

.main-reviews__author {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  letter-spacing: 0.05em;
  white-space: pre-wrap;
  margin: 0;
}

.main-reviews__back-button {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 46px;
  width: 46px;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  z-index: 1;
}

.main-reviews__forward-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 46px;
  width: 46px;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
}

@media screen and (max-width: 950px) {
  .main-reviews {
    height: 390px;
  }
  .main-reviews__container {
    width: calc(100% - 40px);
    height: 390px;
  }
  .main-reviews__review {
    height: 370px;
  }
  .main-reviews__transition-container {
    padding: 80px 85px 80px;
    height: 230px;
  }
  .main-reviews__back-button {
    height: 40px;
  }
  .main-reviews__forward-button {
    height: 40px;
  }
}

@media screen and (max-width: 770px) {
  .main-reviews {
    height: 370px;
  }
  .main-reviews__container {
    height: 370px;
  }
  .main-reviews__review {
  }
  .main-reviews__transition-container {
    padding: 60px 55px 60px;
    height: 250px;
  }
  .main-reviews__back-button {
    height: 35px;
    left: 5px;
  }
  .main-reviews__forward-button {
    height: 35px;
    right: 5px;
  }
}

@media screen and (max-width: 650px) {
  .main-reviews {
    height: 330px;
  }
  .main-reviews__container {
    height: 330px;
  }
  .main-reviews__review {
    height: 330px;
  }
  .main-reviews__transition-container {
    padding: 40px;
  }
  .main-reviews__image {
    width: 50px;
  }
  .main-reviews__quote {
    font-size: 16px;
  }
  .main-reviews__author {
    font-size: 16px;
  }
  .main-reviews__back-button {
    height: 30px;
    left: -3px;
  }
  .main-reviews__forward-button {
    height: 30px;
    right: -3px;
  }
}

@media screen and (max-width: 550px) {
  .main-reviews {
    height: 380px;
    margin: 0;
  }
  .main-reviews__container {
    height: 380px;
  }
  .main-reviews__review {
    height: 320px;
  }
  .main-reviews__transition-container {
    height: 300px;
  }
}

@media screen and (max-width: 500px) {
  .main-reviews {
    height: 400px;
  }
  .main-reviews__container {
    height: 400px;
  }
  .main-reviews__review {
    height: 340px;
  }
  .main-reviews__transition-container {
    height: 320px;
  }
}

@media screen and (max-width: 470px) {
  .main-reviews {
    height: 410px;
    margin: 0 0 20px;
  }
  .main-reviews__container {
    height: 410px;
  }
  .main-reviews__review {
    height: 370px;
  }
  .main-reviews__transition-container {
    height: 330px;
  }
}

@media screen and (max-width: 428px) {
  .main-reviews {
    height: 410px;
  }
  .main-reviews__container {
    height: 410px;
  }
  .main-reviews__review {
    height: 370px;
  }
  .main-reviews__transition-container {
    height: 330px;
  }
}

@media screen and (max-width: 400px) {
  .main-reviews {
    height: 440px;
  }
  .main-reviews__container {
    height: 440px;
  }
  .main-reviews__review {
    height: 390px;
  }
  .main-reviews__transition-container {
    height: 360px;
  }
}

@media screen and (max-width: 390px) {
  .main-reviews {
    height: 460px;
    margin: 0;
  }
  .main-reviews__container {
    height: 460px;
  }
  .main-reviews__review {
    height: 380px;
  }
  .main-reviews__transition-container {
    height: 380px;
  }
}
