.about-owners {
  margin: 0;
}

.about-owners__image {
  background-image: url("../../images/showcaseCards/bathroomCabinetsNew.png");
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-owners__text-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
  width: calc(100% - 80px);
  margin: auto;
  padding: 70px 0 90px;
}

.about-owners__header {
  font-family: "Lora", serif;
  font-size: 55px;
  font-weight: 400;
  letter-spacing: 7px;
  margin: 0 0 80px;
}

.about-owners__paragraph {
  font-family: "Nunito", sans-serif;
  font-size: 19px;
  font-weight: 300;
  letter-spacing: 1px;
  max-width: 1200px;
  margin: 0;
}

@media screen and (max-width: 950px) {
  .about-owners__text-container {
    width: calc(100% - 40px);
    padding: 50px 0 70px;
  }
  .about-owners__header {
    margin: 0 0 60px;
  }
  .about-owners__paragraph {
    font-size: 18px;
  }
}

@media screen and (max-width: 800px) {
  .about-owners__text-container {
  }
  .about-owners__header {
    margin: 0 0 30px;
  }
  .about-owners__paragraph {
  }
}

@media screen and (max-width: 715px) {
  .about-owners__text-container {
  }
  .about-owners__header {
    font-size: 50px;
  }
  .about-owners__paragraph {
  }
}

@media screen and (max-width: 550px) {
  .about-owners__text-container {
  }
  .about-owners__header {
    font-size: 40px;
    margin: 0 0 20px;
  }
  .about-owners__paragraph {
  }
}

@media screen and (max-width: 435px) {
  .about-owners__text-container {
  }
  .about-owners__header {
    font-size: 35px;
  }
  .about-owners__paragraph {
  }
}
