.main-title {
  padding: 0 40px 0;
}

.main-title__text-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 40px auto 45px;
  gap: 15px;
}

.main-title__title {
  font-family: "lora", sans-serif, Arial;
  font-size: 60px;
  font-weight: 400;
  margin: 0;
}

.main-title__title-bold {
  color: hsl(345deg 100% 47%);
}

.main-title__subtitle {
  font-family: "Figtree", sans-serif;
  font-size: 20px;
  max-width: 1300px;
  margin: 0 auto 0;
  line-height: 35px;
}

@media screen and (max-width: 1145px) {
  .main-title__title {
    font-size: 50px;
  }
  .main-title__subtitle {
    font-size: 18px;
    line-height: 30px;
  }
}

@media screen and (max-width: 970px) {
  .main-title__text-container {
    gap: 10px;
  }
  .main-title__title {
    font-size: 40px;
  }
  .main-title__subtitle {
    font-size: 17px;
    line-height: 25px;
  }
}

@media screen and (max-width: 950px) {
  .main-title {
    padding: 0 20px 0;
  }
}

@media screen and (max-width: 780px) {
  .main-title__text-container {
    gap: 5px;
  }
  .main-title__title {
    font-size: 35px;
  }
  .main-title__subtitle {
    font-size: 16px;
  }
}

@media screen and (max-width: 455px) {
  .main-title__title {
    font-size: 33px;
  }
}

@media screen and (max-width: 450px) {
  .main-title__title {
    font-size: 31px;
  }
}

@media screen and (max-width: 412px) {
  .main-title__title {
    font-size: 28px;
  }
}
