.services-submenu {
  width: 248px;
  position: absolute;
  list-style: none;
  text-align: start;
  padding: 0;
  top: 95.5px;
  z-index: 3;
}

.services-submenu.clicked {
  display: none;
}

.dropdown__li {
  background-color: rgba(0, 41, 75, 0.7);
  cursor: pointer;
}

.dropdown__li:last-child:hover {
  /* background-color: rgba(21, 23, 23); */
  background-color: rgba(0, 41, 75, 0.9);
  width: 248px;
  color: #e5e5e5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dropdown__li:hover {
  /* background-color: rgba(21, 23, 23); */
  background-color: rgba(0, 41, 75, 0.9);
  width: 248px;
  color: #e5e5e5;
}

.dropdown__li:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.nav-item {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
  padding: 16px;
}

.nav-item:hover {
  color: #e5e5e5;
}
